import React from "react"
import TreeMenu, {
  Item as TreeMenuItemProps,
  TreeMenuProps,
  ItemComponent,
} from "react-simple-tree-menu"
import { navigate } from "gatsby"

type Props = {
  menuItems: TreeMenuProps["data"]
  initActiveMenu: TreeMenuState
}

const PanelMenu: React.VFC<Props> = ({ menuItems, initActiveMenu }) => {
  const handleOnClickTreeMenu = async ({
    hasNodes,
    isOpen,
    level, // level start with 0
    key,
    label,
    url,
  }: TreeMenuItemProps) => {
    if (level === 2 && !hasNodes && url) {
      await navigate(url)
    }
  }

  return (
    <div className="panel">
      <p className="panel-heading">คลังบทความของบล็อก</p>
      <div>
        <TreeMenu
          cacheSearch
          data={menuItems}
          debounceTime={125}
          disableKeyboard={false}
          hasSearch={false}
          onClickItem={handleOnClickTreeMenu}
          resetOpenNodesOnDataUpdate={false}
          // initialOpenNodes={["2021", "2021/08"]}
          // initialActiveKey={"2021/08/evotech-doc"}
          initialOpenNodes={initActiveMenu.openNodes}
          initialActiveKey={initActiveMenu.activeKey}
        >
          {({ items }) => (
            <ul className="rstm-tree-item-group">
              {items.map(({ key, ...props }) => (
                <ItemComponent
                  key={key}
                  {...props}
                  onClick={!props.hasNodes ? props.onClick : () => {}}
                />
              ))}
            </ul>
          )}
        </TreeMenu>
      </div>
    </div>
  )
}

export default PanelMenu
