const MONTHS: IStringTMap<string> = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December",
}

const SOCIAL_LINKS = {
  FB: "https://www.facebook.com/landometer",
  LINE: "https://line.me/ti/g2/gSXlyzcY_zk73n_IUruI5A",
  LINKEDIN: "https://www.linkedin.com/company/landometer",
  EMAIL: "hello@landometer.com",
}

export { MONTHS, SOCIAL_LINKS }
