import React from "react"
import { Link } from "gatsby"
import {
  FaHeart,
  FaFacebook,
  FaLinkedin,
  FaLine,
  FaEnvelope,
} from "react-icons/fa"
import { SOCIAL_LINKS } from "@src/contants"
const socialList = [
  {
    icon: <FaFacebook className="fa-fb is-size-3" />,
    link: SOCIAL_LINKS.FB,
  },
  {
    icon: <FaLinkedin className="fa-linkedin is-size-3" />,
    link: SOCIAL_LINKS.LINKEDIN,
  },
  {
    icon: <FaLine className="fa-line is-size-3" />,
    link: SOCIAL_LINKS.LINE,
  },
]

const Footer = () => (
  <footer className="footer">
    <div>
      <p className="is-flex is-justify-content-center is-align-items-center content">
        Landometer. Made with <FaHeart className="mx-1 fa-heart" /> in Bangkok,
        Thailand.
      </p>
    </div>

    <div className="has-text-centered mt-5">
      <ul className="social-link">
        <li>
          <a href={`mailto:${SOCIAL_LINKS.EMAIL}`}>
            <FaEnvelope className="fa-envelope is-size-3" />
          </a>
        </li>
        {socialList.map((i, key) => (
          <li key={key}>
            <Link target="_blank" to={i.link}>
              {i.icon}
            </Link>
          </li>
        ))}
      </ul>
    </div>
    <hr />
    <p className="has-text-centered content">
      © 2021 Alice. All Rights Reserved.
    </p>
  </footer>
)

export default Footer
