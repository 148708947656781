import truncate from "truncate-html"

export function isEmptyObj(obj: object) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }

  return JSON.stringify(obj) === JSON.stringify({})
}

export function getExcerptText(
  html: string,
  pruneLength: number = 100
): string {
  return truncate(html, pruneLength, { stripTags: true })
}
