import React, { useState } from "react"
import { Link } from "gatsby"
import { FaGithub, FaHeart } from "react-icons/fa"

interface IHeaderProps {
  siteTitle: string
}

type MenuProps = {
  to: string
  label: string
}

const menus: MenuProps[] = [
  {
    to: "/",
    label: "หน้าแรก",
  },
  {
    to: "/blog/",
    label: "บทความทั้งหมด",
  },
]

const Header: React.VFC<IHeaderProps> = ({ siteTitle }) => {
  const [isActive, setisActive] = useState<boolean>(false)

  return (
    <header>
      <nav
        className="navbar blog-navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img
              src="/images/logo.png"
              style={{ marginRight: 5, height: 28 }}
            />
            <strong>{siteTitle}</strong>
          </Link>

          <a
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar-basic"
            onClick={() => {
              setisActive(!isActive)
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbar-basic"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
        >
          <div className="navbar-start">
            {menus.map((menu, key) => (
              <Link
                key={key}
                to={menu.to}
                className="navbar-item"
                activeClassName="is-active"
              >
                {menu.label}
              </Link>
            ))}

            <a
              className="navbar-item"
              href="https://github.com/theshifters/landometer"
              target="_blank"
            >
              <FaGithub style={{ marginRight: 5 }} /> Github
            </a>

            <a href="#" className="navbar-item">
              <FaHeart style={{ marginRight: 5 }} />
              Sponsor
            </a>
          </div>
          <div className="navbar-end"></div>
        </div>
      </nav>
    </header>
  )
}

export default Header
