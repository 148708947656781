import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import {
  MarkdownRemarkFrontmatter,
  MarkdownRemarkFields,
  Maybe,
} from "types/gatsby-graphql"

import { getExcerptText } from "@utils/blogUtils"

interface ICardProps {
  fields: MarkdownRemarkFields
  frontmatter: MarkdownRemarkFrontmatter
  excerpt: Maybe<string> | undefined
}

const Card = ({ frontmatter, fields, excerpt }: ICardProps) => {
  const slug = fields?.slug!!
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image">
          <Link to={slug}>
            <GatsbyImage
              alt=""
              image={
                frontmatter.featuredImage?.childImageSharp
                  ?.gatsbyImageData as IGatsbyImageData
              }
            />
          </Link>
        </figure>
      </div>
      <div className="card-content content">
        <Link to={slug}>
          <h4 className="mb-0 has-text-primary">
            {getExcerptText(frontmatter.title!!, 40)}
          </h4>
        </Link>
        <small>{frontmatter.date}</small>
        {excerpt ? <p className="mt-3 is-size-7">{excerpt}</p> : null}
      </div>
    </div>
  )
}

export default Card
