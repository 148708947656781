/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useMemo, useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
import PanelMenu from "./PanelMenu"
import { MONTHS } from "../../contants"
import { Query } from "types/gatsby-graphql"
import { TreeMenuProps } from "react-simple-tree-menu"
import { getExcerptText } from "@utils/blogUtils"

type ListSlugMap = IStringTMap<
  IStringTMap<
    {
      title: string
      titleSlug: string
      slug: string
    }[]
  >
>

const MainLayout: React.FC = ({ children }) => {
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [activeTreeMenu, setActiveTreeMenu] = useState<TreeMenuState>({
    openNodes: undefined,
    activeKey: undefined,
  })

  const location = useLocation()
  const data = useStaticQuery<Query>(graphql`
    query MainLayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)

  const listSlugMap = useMemo<ListSlugMap>(() => {
    const result: ListSlugMap = {}
    data.allMarkdownRemark.edges.forEach(edge => {
      const slug = edge.node.fields?.slug
      const title = edge.node.frontmatter?.title || ""
      if (slug) {
        const splitSlug = slug.split("/")
        // console.log("splitSlug", splitSlug)

        const year = splitSlug[2]
        const month = splitSlug[3]

        if (!result[year]) {
          result[year] = {}
        }

        if (!result[year][month]) {
          result[year][month] = []
        }

        result[year][month].push({
          title: title,
          titleSlug: splitSlug[splitSlug.length - 2],
          slug: slug,
        })
      }
    })

    return result
  }, [data.allMarkdownRemark])

  const treeMenuItems = useMemo<TreeMenuProps["data"]>(() => {
    // console.log("listSlugMap", listSlugMap)
    return Object.keys(listSlugMap)
      .map(year => {
        const monthList = Object.keys(listSlugMap[year])

        return {
          key: year,
          label: `${year} (${monthList.reduce(
            (sum, current) => sum + listSlugMap[year][current].length,
            0
          )})`,
          nodes: monthList.map(month => {
            const blogList = listSlugMap[year][month]
            return {
              key: month,
              label: `${MONTHS[month]} (${Object.keys(blogList).length})`,
              nodes: blogList.map((blog, key) => ({
                // key: `${month}-${key.toString()}`,
                key: blog.titleSlug,
                label: getExcerptText(blog.title, 40),
                url: blog.slug,
              })),
            }
          }),
        }
      })
      .sort((a, b) => (a.key < b.key ? 1 : -1))
  }, [listSlugMap])

  useEffect(() => {
    if (location.pathname !== "/") {
      // console.log("location.pathname", location.pathname)
      const splitPath = location.pathname.split("/")
      // console.log("splitPath", splitPath)
      if (splitPath.length === 6) {
        const yearAndMonth = `${splitPath[2]}/${splitPath[3]}`
        setActiveTreeMenu({
          openNodes: [splitPath[2], yearAndMonth],
          activeKey: `${yearAndMonth}/${splitPath[4]}`,
        })
      }
    }
    setIsMounted(true)
  }, [location])

  return (
    <>
      <Header siteTitle={data.site?.siteMetadata?.title || `Blog Title`} />

      <div className="container">
        <div className="columns py-6">
          <div className="column is-3 is-12-mobile">
            <div className="sticky-menu">
              {isMounted ? (
                <PanelMenu
                  menuItems={treeMenuItems}
                  initActiveMenu={activeTreeMenu}
                />
              ) : null}
            </div>
          </div>

          <div className="column is-9 is-12-mobile">{children}</div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default MainLayout
