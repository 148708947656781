/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet, HelmetProps } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { Query } from "types/gatsby-graphql"

type SeoProps = {
  title: string
  description?: string
  keywords?: string
  image?: string
  lang?: string
  meta?: HelmetProps["meta"]
}

function Seo({
  title,
  description = "",
  keywords = "",
  image = "/images/logo-banner.png",
  lang = "en",
  meta = [],
}: SeoProps) {
  const { site } = useStaticQuery<Query>(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site?.siteMetadata?.description
  const defaultTitle = site?.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle} | %s` : `%s`}
      meta={
        [
          // Primary Meta Tags
          {
            name: `title`,
            content: defaultTitle ? `${defaultTitle} | ${title}` : title,
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `keywords`,
            content: `คำนวณภาษีที่ดิน, ที่ดิน, portfolio, สมุดบัญชีที่ดิน${
              keywords ? ", " + keywords : ""
            }`,
          },

          // Open Graph / Facebook
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:url`,
            content: site?.siteMetadata?.siteUrl,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: image,
          },

          // Twitter
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:url`,
            content: site?.siteMetadata?.siteUrl,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:creator`,
            content: site?.siteMetadata?.author || ``,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: image,
          },
        ].concat(meta as any) as HelmetProps["meta"]
      }
    />
  )
}

export default Seo
